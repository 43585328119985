.certificate-component-container {
  gap: 3;
  width: 344px;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: flex-start;
  background-color: #0a1328;
}
.certificate-component-container-image {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  padding-top: 17.5px;
  padding-left: 77px;
  padding-right: 77px;
  flex-direction: column;
  padding-bottom: 17.5px;
}
.certificate-component-image {
  width: 200px;
  object-fit: cover;
}
.certificate-component-container-description {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-color: #EFEFEF;
  border-width: 0px;
  flex-direction: column;
  justify-content: center;
  border-top-width: 1px;
}
.certificate-component-text {
  color: #ffffff;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
}






@media(max-width: 1600px) {
  .certificate-component-text {
    padding: var(--dl-space-space-halfunit);
  }
}
