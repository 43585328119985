.home-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-black);
}
.home-header {
  top: 0px;
  flex: 1;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  opacity: 0.9;
  position: absolute;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-container-header {
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  position: fixed;
  align-items: flex-start;
}
.home-sub-container-logo {
  gap: 0;
  top: 0px;
  flex: 0 0 auto;
  left: var(--dl-space-space-unit);
  width: 240px;
  height: 60px;
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-end;
}
.home-link {
  display: contents;
}
.home-dev-ops-solutions {
  color: rgb(245, 241, 241);
  opacity: 1;
  align-self: center;
  padding-left: 0px;
  letter-spacing: 1px;
  text-decoration: none;
}
.home-link01 {
  display: contents;
}
.home-logo {
  top: 0px;
  flex: 1;
  left: var(--dl-space-space-doubleunit);
  width: auto;
  bottom: 0px;
  height: auto;
  margin: auto;
  z-index: 1;
  position: absolute;
  align-self: flex-start;
  object-fit: cover;
  text-decoration: none;
  border-top-left-radius: var(--dl-radius-radius-radius2);
  border-top-right-radius: var(--dl-radius-radius-radius2);
  border-bottom-left-radius: var(--dl-radius-radius-radius2);
  border-bottom-right-radius: var(--dl-radius-radius-radius2);
}
.home-link02 {
  display: contents;
}
.home-linkedin {
  top: 1px;
  flex: 0;
  right: var(--dl-space-space-tenunits);
  width: auto;
  height: auto;
  padding: var(--dl-space-space-halfunit);
  z-index: 1;
  position: fixed;
  align-self: center;
  object-fit: cover;
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
  border-top-left-radius: var(--dl-radius-radius-radius2);
  border-top-right-radius: var(--dl-radius-radius-radius2);
  border-bottom-left-radius: var(--dl-radius-radius-radius2);
  border-bottom-right-radius: var(--dl-radius-radius-radius2);
}
.home-link03 {
  display: contents;
}
.home-github {
  top: 0px;
  right: var(--dl-space-space-fiveunits);
  width: auto;
  height: auto;
  padding: var(--dl-space-space-halfunit);
  position: fixed;
  align-self: center;
  object-fit: cover;
  padding-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-about-me-hero {
  width: 100%;
  display: flex;
  opacity: 0.8;
  max-width: var(--dl-size-size-maxcontent);
  margin-top: var(--dl-space-space-fiveunits);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  border-radius: var(--dl-radius-radius-radius4);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  background-size: cover;
  justify-content: space-between;
  background-image: url("/adobestock.svg");
  border-top-right-radius: var(--dl-radius-radius-radius2);
}
.home-hero-text-container {
  width: 38%;
  display: flex;
  align-items: flex-start;
  margin-left: 50px;
  flex-direction: column;
}
.home-name {
  color: rgb(255, 255, 255);
  z-index: 100;
  font-style: normal;
  font-family: "Plus Jakarta Sans";
  font-weight: 800;
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-title {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-family: "Plus Jakarta Sans";
  font-weight: 200;
}
.home-short-description {
  color: var(--dl-color-gray-white);
  font-family: "Plus Jakarta Sans";
  padding-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-tripleunit);
}
.home-text01 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-text03 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.home-container-buttons {
  flex: auto;
  width: 85%;
  height: 100px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-self: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}
.home-cta-btn {
  color: var(--dl-color-gray-white);
  width: 189px;
  height: 41px;
  position: relative;
  align-self: flex-start;
  text-align: center;
  border-radius: var(--dl-radius-radius-radius4);
  background-color: var(--dl-color-primary-100);
}
.home-text05 {
  top: 7px;
  left: 0px;
  right: 0px;
  margin: auto;
  position: absolute;
  align-self: center;
}
.home-button {
  top: 0px;
  right: 0px;
  width: 189px;
  height: 41px;
  display: flex;
  position: absolute;
  text-align: center;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
}
.home-text08 {
  align-self: center;
  border-width: 0px;
}
.home-text09 {
  font-style: normal;
  font-weight: 700;
}
.home-section-separator {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  flex-direction: column;
}
.home-about-me {
  color: #f9f5f5;
  width: auto;
  height: auto;
  font-size: 36px;
  align-self: flex-start;
  padding-top: 0px;
}
.home-about-me-content {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  padding-right: var(--dl-space-space-tripleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-tenunits);
  background-size: cover;
  background-image: ee24bb8f-3ffe-4f7c-957f-5ebbdee070fe;
}
.home-container1 {
  width: 100%;
  height: 304px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-tripleunit);
  justify-content: flex-start;
}
.home-image {
  width: auto;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.home-container2 {
  flex: 0 0 auto;
  width: 80%;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: column;
  justify-content: center;
}
.home-quotes {
  width: auto;
  object-fit: cover;
  padding-left: var(--dl-space-space-tripleunit);
}
.home-container3 {
  flex: 0 0 1;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text11 {
  color: var(--dl-color-gray-white);
  width: 100%;
  font-size: 31px;
  font-style: normal;
  text-align: left;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  margin-left: var(--dl-space-space-tripleunit);
  letter-spacing: 1px;
}
.home-container4 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  border-color: rgba(120, 120, 120, 0.4);
  border-style: double;
  border-width: 2px;
  flex-direction: column;
}
.home-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-heading-container {
  width: 45%;
  display: flex;
  align-self: flex-start;
  align-items: baseline;
  margin-bottom: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-description {
  color: rgb(253, 250, 250);
  text-align: left;
  font-family: Open Sans;
  border-color: #d22020;
  border-style: dotted;
  border-width: 4x;
}
.home-nr-container {
  flex: 0 0 auto;
  width: 200px;
  height: auto;
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-fourunits);
  justify-content: flex-end;
}
.home-completed-proj-container {
  gap: gap;
  flex: 0 0 auto;
  width: auto;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.home-nr-completed-proj {
  color: rgb(23, 59, 221);
  width: auto;
  font-size: 100px;
  align-self: center;
  text-align: center;
  text-transform: lowercase;
}
.home-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-plus {
  color: rgb(23, 59, 221);
  width: auto;
  font-size: 100px;
  align-self: center;
  text-align: center;
  text-transform: lowercase;
}
.home-text20 {
  font-size: 50px;
}
.home-completed-proj-desc-container {
  flex: 0 0 1;
  width: 550px;
  height: 100%;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-completed-proj-heading {
  color: rgb(245, 240, 240);
  font-size: 25px;
}
.home-description1 {
  color: rgb(248, 248, 249);
  width: auto;
  font-size: 23px;
  align-self: flex-start;
}
.home-text22 {
  font-size: 20px;
}
.home-text23 {
  font-size: 20px;
}
.home-text24 {
  text-transform: lowercase;
}
.home-section-separator1 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  flex-direction: column;
}
.home-skills {
  color: rgb(249, 245, 245);
  width: auto;
  height: auto;
  font-size: 36px;
  align-self: flex-start;
  padding-top: 0px;
}
.home-skills1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-tenunits);
  justify-content: space-between;
}
.home-container-skills {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  flex-direction: row;
}
.home-skills-certs-separator {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding-left: 15%;
  padding-right: 15%;
  flex-direction: column;
  padding-bottom: 5%;
}
.home-certs {
  color: rgb(249, 245, 245);
  width: auto;
  height: auto;
  font-size: 36px;
  align-self: flex-start;
  padding-top: 0px;
}
.home-section-separator2 {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: flex-start;
  border-color: #ffffff;
  border-width: 1px;
  flex-direction: column;
  background-color: var(--dl-color-gray-700);
}
.home-certicates-container {
  gap: var(--dl-space-space-tripleunit);
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding-left: 15%;
  padding-right: 15%;
  flex-direction: row;
  justify-content: flex-start;
}
.home-link04 {
  display: contents;
}
.home-component08 {
  text-decoration: none;
}
.home-link05 {
  display: contents;
}
.home-component09 {
  text-decoration: none;
}
.home-link06 {
  display: contents;
}
.home-component10 {
  text-decoration: none;
}
.home-link07 {
  display: contents;
}
.home-component11 {
  text-decoration: none;
}
.home-link08 {
  display: contents;
}
.home-component12 {
  text-decoration: none;
}
.home-link09 {
  display: contents;
}
.home-component13 {
  text-decoration: none;
}
.home-certs-work-exp-separator {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: 15%;
  padding-right: 15%;
  flex-direction: column;
  padding-bottom: 5%;
}
.home-work-exp {
  color: rgb(249, 245, 245);
  width: auto;
  height: auto;
  font-size: 36px;
  align-self: flex-start;
  padding-top: 0px;
}
.home-work-exp-container {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding-left: 15%;
  padding-right: 15%;
  flex-direction: column;
}
.home-contact {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxcontent);
  align-items: flex-start;
  padding-top: var(--dl-space-space-tenunits);
  padding-left: var(--dl-space-space-tripleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
}
.home-content-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-self: stretch;
  align-items: flex-start;
  border-color: var(--dl-color-gray-700);
  border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-get-in-touch {
  color: var(--dl-color-gray-white);
  font-size: 48px;
  margin-top: var(--dl-space-space-fiveunits);
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.22;
  margin-bottom: var(--dl-space-space-fiveunits);
  text-transform: none;
  text-decoration: none;
}
.home-contact-method-container {
  width: auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-email {
  width: 298px;
  height: 144px;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-link10 {
  display: contents;
}
.home-email1 {
  width: auto;
  height: 50%;
  margin: var(--dl-space-space-halfunit);
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.home-email-address {
  color: var(--dl-color-gray-white);
  align-self: center;
  padding-left: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-phone {
  width: 298px;
  height: 144px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  margin-right: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-link11 {
  display: contents;
}
.home-cellphone {
  width: 27%;
  height: 55%;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.home-phone-number {
  color: var(--dl-color-gray-white);
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  text-decoration: none;
}
@media(max-width: 1600px) {
  .home-about-me-hero {
    position: relative;
  }
  .home-container-buttons {
    position: relative;
  }
  .home-text06 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.55;
    text-transform: capitalize;
    text-decoration: none;
  }
  .home-text09 {
    background-color: transparent;
  }
  .home-text10 {
    background-color: var(--dl-color-primary-100);
  }
  .home-section-separator {
    padding-left: 7%;
  }
  .home-container1 {
    margin: var(--dl-space-space-unit);
  }
  .home-text11 {
    color: var(--dl-color-gray-white);
    width: 100%;
    font-style: normal;
    text-align: left;
    margin-left: var(--dl-space-space-tripleunit);
    letter-spacing: 1px;
  }
  .home-container5 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .home-description {
    color: rgb(253, 250, 250);
    font-family: Open Sans;
    line-height: 1.75;
  }
  .home-text15 {
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    text-decoration: none;
  }
  .home-section-separator1 {
    padding-left: 7%;
  }
  .home-container-skills {
    padding-left: 7%;
  }
  .home-skills-certs-separator {
    padding-left: 7%;
  }
  .home-certicates-container {
    padding-left: 7%;
  }
  .home-certs-work-exp-separator {
    padding-left: 7%;
  }
}
@media(max-width: 1200px) {
  .home-hero-text-container {
    width: 40%;
  }
  .home-container2 {
    width: 947px;
  }
  .home-container3 {
    align-self: center;
    align-items: center;
  }
  .home-text11 {
    color: var(--dl-color-gray-white);
    font-style: normal;
    margin-left: var(--dl-space-space-tripleunit);
  }
  .home-skills1 {
    position: relative;
  }
}
@media(max-width: 991px) {
  .home-about-me-hero {
    flex-direction: column-reverse;
  }
  .home-hero-text-container {
    width: 80%;
    margin-left: 0px;
  }
  .home-short-description {
    color: var(--dl-color-gray-white);
  }
  .home-container-buttons {
    width: 100%;
    max-width: 60%;
    align-items: flex-start;
  }
  .home-cta-btn {
    width: 40%;
  }
  .home-text06 {
    width: 40%;
  }
  .home-button {
    margin-right: 15%;
  }
  .home-container2 {
    flex: auto;
  }
  .home-container3 {
    width: 100%;
  }
  .home-completed-proj-desc-container {
    width: 300px;
  }
  .home-skills-certs-separator {
    padding-bottom: 7%;
  }
  .home-work-exp-container {
    padding-left: 5%;
    padding-right: 10%;
  }
  .home-contact-method-container {
    flex-wrap: wrap;
  }
  .home-email {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
  .home-phone {
    margin-bottom: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .home-hero-text-container {
    position: relative;
  }
  .home-name {
    text-align: center;
  }
  .home-short-description {
    text-align: justify;
  }
  .home-container-buttons {
    width: 70%;
    flex-wrap: wrap;
    max-width: 100%;
    align-self: flex-start;
    align-items: stretch;
    flex-direction: row;
  }
  .home-cta-btn {
    width: 189px;
    align-self: center;
  }
  .home-text06 {
    width: auto;
    align-self: center;
  }
  .home-button {
    right: 0px;
    margin-left: 0px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-container3 {
    width: 100%;
  }
  .home-heading-container {
    width: 100%;
  }
  .home-skills-certs-separator {
    padding-bottom: 10%;
  }
  .home-certs-work-exp-separator {
    padding-bottom: 10%;
  }
  .home-work-exp-container {
    padding-left: 0;
    padding-right: 0;
  }
  .home-contact {
    padding-right: var(--dl-space-space-tripleunit);
    flex-direction: column;
  }
  .home-content-container {
    width: 100%;
  }
}
@media(max-width: 390px) {
  .home-hero-text-container {
    flex: 1;
    width: 100%;
    position: relative;
    align-self: center;
    align-items: flex-start;
  }
  .home-container-buttons {
    max-width: 100%;
    max-height: 100%;
  }
  .home-cta-btn {
    right: 0px;
    align-self: flex-start;
  }
  .home-text05 {
    font-size: 15px;
  }
  .home-button {
    position: static;
    font-size: 14px;
  }
  .home-text08 {
    width: 139;
    font-size: 15px;
  }
  .home-container1 {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .home-container2 {
    width: 95%;
    align-items: flex-start;
  }
  .home-quotes {
    padding-top: var(--dl-space-space-fourunits);
  }
  .home-container3 {
    flex: 1;
    width: 100%;
    height: 100%;
  }
  .home-text11 {
    height: 100%;
  }
  .home-container5 {
    flex: 1;
    padding-top: 73%;
  }
  .home-heading-container {
    flex: 0 0 1;
  }
  .home-nr-container {
    width: auto;
    justify-content: center;
  }
  .home-certs-work-exp-separator {
    padding-bottom: 14%;
  }
  .home-work-exp-container {
    padding-left: 0;
    padding-right: 0;
  }
  .home-content-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-get-in-touch {
    text-align: center;
  }
  .home-contact-method-container {
    margin-bottom: 0px;
  }
  .home-email {
    margin-right: 0px;
  }
  .home-phone {
    margin-right: 0px;
  }
}
