.experience-component-container {
  width: 100%;
  height: 420px;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  position: relative;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-unit);
  align-items: flex-start;
  padding-top: var(--dl-space-space-doubleunit);
  border-color: #fbf6f6;
  border-width: 0.5px;
  padding-left: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.experience-component-container1 {
  flex: 0 0 auto;
  width: 15%;
  height: 13%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.experience-component-company-text {
  color: #fffefe;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.experience-component-position-text {
  color: #ffffff;
  width: 100%;
  height: 100%;
  font-size: 17px;
  padding-top: var(--dl-space-space-tripleunit);
}
.experience-component-container2 {
  flex: 0 0 auto;
  width: 19%;
  height: 13%;
  display: flex;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
}
.experience-component-period-text {
  color: #ffffff;
  width: 100%;
  height: 100%;
}
.experience-component-container3 {
  gap: 0;
  flex: 0 0 auto;
  width: 45%;
  height: 100%;
  margin: 2px;
  display: flex;
  position: relative;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.experience-component-description-text {
  color: #ffffff;
  width: 100%;
  height: auto;
  margin-top: 0px;
  margin-bottom: var(--dl-space-space-halfunit);
}
.experience-component-description-text1 {
  color: #ffffff;
  width: 100%;
  height: auto;
  margin-top: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.experience-component-description-text2 {
  color: #ffffff;
  width: 100%;
  margin-top: var(--dl-space-space-halfunit);
}



@media(max-width: 1600px) {
  .experience-component-container3 {
    font-size: 17px;
  }
}
@media(max-width: 1200px) {
  .experience-component-container3 {
    font-size: 15px;
  }
}
@media(max-width: 991px) {
  .experience-component-container2 {
    font-size: 80%;
  }
  .experience-component-container3 {
    font-size: 13px;
  }
}
@media(max-width: 767px) {
  .experience-component-container3 {
    font-size: 14px;
  }
}
@media(max-width: 390px) {
  .experience-component-container1 {
    font-size: 10px;
  }
  .experience-component-company-text {
    font-size: 17px;
  }
  .experience-component-position-text {
    font: 14px;
  }
  .experience-component-container3 {
    font-size: 10px;
  }
}
